import { ListGroup, Spinner } from "react-bootstrap";
import { MediaSource } from "../../../models/media/MediaSource";
import { DateFormats } from "../../../utilities/dates";
import dayjs from "dayjs";
import { SetStateAction, useEffect, useState } from "react";
import { useGetStoredFileQuery } from "../../../store/api/kinesense";
import ThumbnailExtractor from "../../../utilities/ThumbnailExtractor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faVideoSlash, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import ReviewVideoListItemFooter from "./tray/ReviewVideoListItemFooter";
import useTagEventsCount from "./useTagEventsCount";

export interface ReviewVideoListItemProps {
    data: MediaSource;
    viewId: string;
    activeMediaId: string;
    setActiveMediaId: (_: SetStateAction<string>) => void;
    thumbnail: string | undefined;
    hasLoadedThumbnails: boolean;
}

function ReviewVideoListItem(props: ReviewVideoListItemProps) {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);

    const isSelected = props.data.mediaId === props.activeMediaId;
    const hasAudio = props.data.files?.initialDisplay?.hasAudio ?? false;
    const duration = dayjs
        ?.duration(Math.round(props.data.duration))
        .format(DateFormats.durationTimeDaysToMilliseconds)
        .split("#");

    function handleOnSelect(e: React.MouseEvent) {
        e.preventDefault();
        props.setActiveMediaId(props.data.mediaId);
    }

    const matchingEventsCount = useTagEventsCount(projectId, props.data.mediaId) ?? 0;

    // BACKUP THUMBNAIL
    const [isLoadingBackupThumbnail, setIsLoadingBackupThumbnail] = useState(false);
    const [backupThumbnailSrc, setBackupThumbnailSrc] = useState<string>(undefined);

    const {
        data: storedFile,
        isSuccess: isSuccessStoredFile,
        isFetching: isFetchingStoredFile,
        isError: isErrorStoredFile,
    } = useGetStoredFileQuery(
        { fileId: props.data?.files.initialDisplay?.fileId },
        {
            skip:
                !props.hasLoadedThumbnails ||
                props.thumbnail !== undefined ||
                props.data?.files.initialDisplay?.fileId === undefined,
        },
    );
    const hasLoadedStoredFile = isSuccessStoredFile && !isFetchingStoredFile;

    useEffect(() => {
        if (!hasLoadedStoredFile) {
            if (isErrorStoredFile) {
                setIsLoadingBackupThumbnail(false);
            }
            return;
        }

        setIsLoadingBackupThumbnail(true);
        ThumbnailExtractor.getThumbnail(storedFile.accessUrl.url, [0, 0, 1, 1], 0)
            .then((r) => {
                setBackupThumbnailSrc(r);
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => {
                setIsLoadingBackupThumbnail(false);
            });
    }, [hasLoadedStoredFile, isErrorStoredFile]);

    function renderImage() {
        const containerStyle = { width: "90px", height: "70px" };
        const containerClass = "flex-shrink-0 d-flex justify-content-center align-items-center";

        if (!props.hasLoadedThumbnails || (props.thumbnail === undefined && isLoadingBackupThumbnail)) {
            return (
                <div className={containerClass} style={containerStyle}>
                    <Spinner size="sm" />
                </div>
            );
        } else if (props.thumbnail === undefined && backupThumbnailSrc === undefined) {
            return (
                <div className={`${containerClass} bg-secondary-subtle`} style={containerStyle} title="No preview">
                    <FontAwesomeIcon icon={faVideoSlash} className="text-secondary-emphasis" />
                </div>
            );
        } else {
            const thumbnailSrc = props.thumbnail ?? backupThumbnailSrc;
            return (
                <div className={containerClass} style={containerStyle}>
                    <img alt="thumbnail" src={thumbnailSrc} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </div>
            );
        }
    }

    return (
        <>
            <ListGroup.Item
                as={"div"}
                action
                onClick={handleOnSelect}
                className={`video-list-item p-0 position-relative rounded border shadow-sm${
                    isSelected ? " selected" : ""
                }`}
            >
                <section className="gap-3 py-2 px-3 d-flex align-items-stretch">
                    {renderImage()}

                    <div className="gap-1 text-truncate text-nowrap flex-grow-1 d-flex flex-column">
                        <span className="fw-bold text-truncate flex-fill text-nowrap">{props.data.name}</span>

                        <div className="video-list-times">
                            <div className="gap-2 d-flex">
                                <span className="text-secondary">Start Time</span>
                                <span className="text-muted flex-fill text-nowrap">
                                    {dayjs(props.data.startsAt).format(DateFormats.dayMonthYearWithTimeSeconds)}
                                </span>
                            </div>
                            <div className="gap-3 d-flex">
                                <span className="text-secondary">Duration</span>
                                <div>
                                    <span className="text-muted">{duration[0]}</span>
                                    <span className="text-secondary seconds-fraction">.{duration[1]}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="flex-shrink-0 justify-content-between d-flex flex-column align-items-end"
                        style={{ width: "25px", fontSize: "0.9em", lineHeight: "0.9em" }}
                    >
                        <div>{hasAudio && <FontAwesomeIcon className="text-secondary" icon={faVolumeHigh} />}</div>
                        {matchingEventsCount > 0 && !isSelected && (
                            <div className="gap-1 d-flex align-items-center text-secondary">
                                <span className="mb-1">{matchingEventsCount}</span>
                                <FontAwesomeIcon icon={faTag} />
                            </div>
                        )}
                    </div>
                </section>

                {isSelected && props.data?.files?.initialDisplay !== undefined && (
                    <ReviewVideoListItemFooter data={props.data} viewId={props.viewId} />
                )}
            </ListGroup.Item>
        </>
    );
}

export default ReviewVideoListItem;
