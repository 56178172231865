import { faClapperboard } from "@fortawesome/free-solid-svg-icons";
import { DataOperation, VizContext } from "./DataOperation";
import NiceModal from "@ebay/nice-modal-react";
import CreateClipModal from "../../../components/dataOperationModals/createVideoClipModal/CreateClipModal";

export class CreateVideoClipOperation extends DataOperation {
    filenamePrefix?: string;

    constructor() {
        super();
        this.operationId = "createVideoClip";
        this.displayName = "Create a video clip from the selected time";
        this.icon = {
            type: "fontawesome",
            icon: faClapperboard,
        };
        this.priority = "primary";
        this.context = "selection";
        this.category = "export";
        NiceModal.register(this.operationId, CreateClipModal);
    }

    canExecute(context: VizContext) {
        return (
            context?.view?.data.hasAssociatedMedia &&
            context.view.cursor !== undefined &&
            context.view.secondaryCursor !== undefined
        );
    }

    execute(context: VizContext) {
        NiceModal.show(this.operationId, { context });
    }
}
