import { nanoid } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { FragmentGroup } from "../../media/FragmentGroup";
import { MediaSource } from "../../media/MediaSource";
import { Entity, EntityImage, EntityImageCollection, FileVideoFrameEntityImage, ThumbnailEntityImage } from "../Entity";
import { DataDescription } from "../dataDescriptions/DataDescription";
import { DateEntityProperty } from "../entityProperties/DateEntityProperty";
import { StringEntityProperty } from "../entityProperties/StringEntityProperty";
import { EntityAdapter } from "./EntityAdapter";
import { NumberListEntityProperty } from "../entityProperties/NumberListEntityProperty";
import { AreaBounds } from "cloud-core/spatial/Spatial";

export type FragmentGroupWithMedia = { group: FragmentGroup; media: MediaSource; runId: number };

export class FragmentGroupEntityAdapter implements EntityAdapter<FragmentGroupWithMedia> {
    dataDescription: DataDescription;

    constructor(dataDescription: DataDescription) {
        this.dataDescription = dataDescription;
    }

    setDataDescription(dataDescription: DataDescription): void {
        this.dataDescription = dataDescription;
    }

    convertToEntity(data: FragmentGroupWithMedia): Entity | undefined {
        if (data?.media?.files?.initialDisplay?.fileId === undefined) {
            return undefined;
        }

        // TODO: remove temp fix
        // Temp fix for bounds with wrong order
        const bounds = data.group.bounds;
        const corrected_bounds: AreaBounds = [
            Math.min(bounds[0], bounds[2]),
            Math.min(bounds[1], bounds[3]),
            Math.max(bounds[0], bounds[2]),
            Math.max(bounds[1], bounds[3]),
        ];

        let mainImage: EntityImage;
        const videoFrameImage = new FileVideoFrameEntityImage({
            timestamp: data.media.startsAt - data.group.startsAt,
            bounds: corrected_bounds,
            fileId: data.media.files.initialDisplay.fileId,
        });
        if (data.group.eventType !== "movement" && data.group.info?.midFragmentId) {
            mainImage = new ThumbnailEntityImage({
                mediaId: data.media.mediaId,
                runId: data.runId,
                fragGroupId: data.group.fragmentGroupId,
                fragmentId: data.group.info.midFragmentId,
                backupImage: videoFrameImage,
            });
        } else {
            mainImage = videoFrameImage;
        }

        return new Entity({
            id: nanoid(16),
            sourceObject: {
                id: data.group.fragmentGroupId,
                mediaSource: data.media,
                mediaSourceFrameOffset: data.group.startsAt - data.media.startsAt,
                runId: data.runId,
                projectId: data.media.projectId,
                object: data.group,
            },
            dataDescription: this.dataDescription,
            images: new EntityImageCollection([mainImage]),
            properties: {
                BoundsBitmap: new StringEntityProperty(data.group.bitmap),
                LowResBoundsBitmap: new StringEntityProperty(data.group.lowResBitmap),
                Bounds: new NumberListEntityProperty(corrected_bounds),
                EventType: new StringEntityProperty(data.group.eventType),
                StartTime: new DateEntityProperty(dayjs(data.group.startsAt).toDate()),
                EndTime: new DateEntityProperty(dayjs(data.group.endsAt).toDate()),
                FragmentGroupId: new StringEntityProperty(data.group.fragmentGroupId),
            },
        });
    }
}

export class MovementFragmentGroupEntityAdapter extends FragmentGroupEntityAdapter {
    convertToEntity(data: FragmentGroupWithMedia): Entity | undefined {
        if (data.group.eventType !== "movement") {
            return undefined;
        }

        return super.convertToEntity(data);
    }
}

export class ObjectClassificationFragmentGroupEntityAdapter extends FragmentGroupEntityAdapter {
    convertToEntity(data: FragmentGroupWithMedia): Entity | undefined {
        if (data.group.eventType !== "object") {
            return undefined;
        }

        const entity = super.convertToEntity(data);
        const confidence = Number(data.group.metadata.ObjectCategoryConf);

        entity.properties["ObjectCategory"] = new StringEntityProperty(
            data.group.metadata.ObjectCategory,
            true,
            isNaN(confidence) ? undefined : confidence,
        );

        return entity;
    }
}
