import { Table } from "react-bootstrap";
import { ReviewVideoListItemTrayContentProps } from "./ReviewVideoListItemFooter";
import { useGetVideoClipsQuery } from "../../../../store/api/kinesense";
import ReviewVideoListItemClip from "./ReviewVideoListItemClip";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";

export default function ReviewVideoListItemClips(props: ReviewVideoListItemTrayContentProps) {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);

    const { data } = useGetVideoClipsQuery(
        { projectId: projectId, mediaId: props.data?.mediaId },
        { skip: !projectId || !props.data?.mediaId },
    );
    const clips = data ?? [];

    return (
        <div ref={props.contentRef} className="inner">
            <section className="overflow-hidden">
                <h4>Clips</h4>
                <div className="overflow-auto" style={{ maxHeight: "30rem" }}>
                    <Table borderless className="details-table">
                        <thead>
                            <tr>
                                <th>Starts at</th>
                                <th>Duration</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {clips.map((c) => {
                                return <ReviewVideoListItemClip key={c.fileId} clip={c} {...props} />;
                            })}
                        </tbody>
                    </Table>
                </div>
            </section>
        </div>
    );
}
