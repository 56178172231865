import "./Tasks.scss";
import { nanoid } from "@reduxjs/toolkit";

export interface TaskCallbacks {
    onFinished: () => Promise<void>;
    refetch: () => Promise<{ hasUpdated: boolean; hasFinished: boolean }>;
    millisecondsToNextUpdate: (countUnsuccessfulUpdates: number) => number;
}

export class Task {
    id: string;
    title: string;
    callbacks: TaskCallbacks | undefined;
    addedAt: Date;
    progress?: number = 0;
    isFinished: boolean = false;

    constructor(title: string, callbacks: TaskCallbacks, id: string) {
        this.id = id;
        this.title = title;
        this.callbacks = callbacks;
        this.addedAt = new Date();
    }

    /** Returns true if a given Task object is equivalent to this Task object */
    public isEquivalent(task: Task) {
        const { id, title } = task;

        return id == this.id && title == this.title;
    }

    /** Finish a task by calling its `onFinished` callback and updating its state. */
    public finish() {
        if (!this.isFinished) {
            this.callbacks.onFinished().then(() => {
                this.isFinished = true;
                this.callbacks = undefined;
            });
        }
    }
}

type SetTaskFn = (tasks: Task[]) => void;

export class TaskService {
    private tasks: Task[] = [];
    private setUiTask: SetTaskFn;

    /** Initial setup for the TaskService to interface with a component */
    public register(setTasksFn: SetTaskFn) {
        this.setUiTask = setTasksFn;
        // Display tasks which were set before registration
        this.setUiTask(this.tasks);
    }

    /** Add a new task to the queue */
    public addTask(title: string, callbacks: TaskCallbacks, id?: string) {
        id = id ?? nanoid();
        const task = new Task(title, callbacks, id);

        // Check for an existing task with the same ID
        for (const [index, existingTask] of this.tasks.entries()) {
            if (existingTask.id != id) {
                continue;
            }

            // If the new task is not equivalent to the existing one, replace it in
            // the tasks array, otherwise just reset addedAt
            if (existingTask.isEquivalent(task)) {
                existingTask.addedAt = new Date();
            } else {
                this.tasks[index] = task;
                this.setUiTask(this.tasks);
            }

            this.setUiTask(this.tasks);
            return;
        }

        this.tasks.unshift(task);
        this.setUiTask(this.tasks);
    }

    /** Remove a completed task */
    public removeCompletedTask(task: Task) {
        if (!task.isFinished) {
            return;
        }

        const taskIndex = this.tasks.indexOf(task);
        if (taskIndex !== -1) {
            this.tasks.splice(taskIndex, 1);
            this.setUiTask(this.tasks);
        }
    }

    /** Remove all completed tasks */
    public clearCompletedTasks() {
        this.tasks = this.tasks.filter((t) => !t.isFinished);
        this.setUiTask(this.tasks);
    }

    /** Returns a boolean based on whether there are currently any active tasks */
    public hasActiveTasks() {
        return this.tasks.find((t) => !t.isFinished) !== undefined;
    }

    /** Returns a boolean based on whether there are currently any completed tasks */
    public hasCompletedTasks() {
        return this.tasks.find((t) => t.isFinished) !== undefined;
    }
}

export const Tasks = new TaskService();
