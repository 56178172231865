import { useEffect, useState, MutableRefObject, useRef } from "react";
import { Form } from "react-bootstrap";

export interface PasswordChangedEventArgs {
    password: string;
    isValid: boolean;
}

export interface NewPasswordInputProps {
    passwordChanged: (data: PasswordChangedEventArgs) => void;
    passwordVisibilityChanged?: (isVisible: boolean) => void;
    size?: "sm" | "lg";
    clearInputsFnRef?: MutableRefObject<() => void>;
}

function NewPasswordInput(props: NewPasswordInputProps) {
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);

    const [showNewPasswordFeedback, setShowNewPasswordFeedback] = useState(false);
    const [showConfirmNewPasswordFeedback, setConfirmNewPasswordFeedback] = useState(false);

    const newPasswordRef = useRef<HTMLInputElement>(undefined);
    const confirmNewPasswordRef = useRef<HTMLInputElement>(undefined);

    const isNewPasswordValid = newPassword.length <= 100 && newPassword.length > 9;
    const isConfirmNewPasswordValid = newPassword == confirmNewPassword;

    // Set callback used in parent component to clear inputs
    if (props.clearInputsFnRef !== undefined && props.clearInputsFnRef.current === undefined) {
        props.clearInputsFnRef.current = () => {
            setNewPassword("");
            setConfirmNewPassword("");
            setIsPasswordRevealed(false);
        };
    }

    function handleNewPasswordInputChanged(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setNewPassword(event.target.value);
    }

    function handleNewPasswordConfirmationInputChanged(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setConfirmNewPassword(event.target.value);
    }

    function handleRevealPasswordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setIsPasswordRevealed(event.target.checked);

        if (props.passwordVisibilityChanged) {
            props.passwordVisibilityChanged(event.target.checked);
        }
    }

    // Only show warnings about invalid passwords when input loses focus
    useEffect(() => {
        if (newPasswordRef.current == undefined) {
            return;
        }

        const focusOut = () => setShowNewPasswordFeedback(true);
        const focusIn = () => setShowNewPasswordFeedback(false);
        newPasswordRef.current?.addEventListener("focusout", focusOut);
        newPasswordRef.current?.addEventListener("focusin", focusIn);

        return () => {
            newPasswordRef.current?.removeEventListener("focusout", focusOut);
            newPasswordRef.current?.removeEventListener("focusin", focusIn);
        };
    }, [newPasswordRef.current]);
    useEffect(() => {
        if (confirmNewPasswordRef.current == undefined) {
            return;
        }

        const focusOut = () => setConfirmNewPasswordFeedback(true);
        const focusIn = () => setConfirmNewPasswordFeedback(false);
        confirmNewPasswordRef.current?.addEventListener("focusout", focusOut);
        confirmNewPasswordRef.current?.addEventListener("focusin", focusIn);

        return () => {
            confirmNewPasswordRef.current?.removeEventListener("focusout", focusOut);
            confirmNewPasswordRef.current?.removeEventListener("focusin", focusIn);
        };
    }, [confirmNewPasswordRef.current]);

    // Sync with parent
    useEffect(() => {
        props.passwordChanged({
            password: newPassword,
            isValid: isNewPasswordValid && isConfirmNewPasswordValid,
        });
    }, [isNewPasswordValid && isConfirmNewPasswordValid]);

    return (
        <>
            <Form.Group>
                <Form.Control
                    autoFocus
                    ref={newPasswordRef}
                    size={props.size}
                    type={isPasswordRevealed ? "text" : "password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handleNewPasswordInputChanged}
                    isValid={isNewPasswordValid}
                    isInvalid={showNewPasswordFeedback && !isNewPasswordValid}
                />

                <Form.Control.Feedback type="invalid">
                    Passwords must be between 10 and 100 characters long
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Form.Control
                    ref={confirmNewPasswordRef}
                    size={props.size}
                    type={isPasswordRevealed ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={handleNewPasswordConfirmationInputChanged}
                    placeholder="Confirm New Password"
                    isValid={isConfirmNewPasswordValid && isNewPasswordValid}
                    isInvalid={showConfirmNewPasswordFeedback && !isConfirmNewPasswordValid}
                />
                <Form.Control.Feedback type="invalid">Passwords do not match</Form.Control.Feedback>
            </Form.Group>

            <Form.Check type="checkbox" label="Show Password" onChange={handleRevealPasswordChanged} />
        </>
    );
}

export default NewPasswordInput;
