import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { IntegrationMediaSource } from "../../../../../models/media/MediaSource";
import { ImportWizardPagePropsBase, SourceSelectionPageData } from "../ImportWizard";
import "./ImportPages.scss";
import { useGetAllIntegrationMediaQuery } from "../../../../../store/api/kinesense";
import ConditionalSpinner from "../../../../../components/ConditionalSpinner";
import BasicAlert from "../../../../../components/BasicAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft, faVideoSlash, faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Notifications } from "../../../../../utilities/Notifications/Notifications";

interface SourceSelectionPageProps extends ImportWizardPagePropsBase {
    selectedFile: File | undefined;
    value: SourceSelectionPageData;
}

function SourceSelectionPage(props: SourceSelectionPageProps) {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(props.selectedFile);
    const isFileSelected = selectedFile !== undefined;
    const [isSupported, setIsSupported] = useState<boolean>(true);
    const [showOvercastLibrary, setShowOvercastLibrary] = useState<boolean>(false);
    const {
        data: overcastData,
        isLoading: overcastIsLoading,
        isSuccess: overcastIsSuccess,
    } = useGetAllIntegrationMediaQuery({ integrationId: "overcast" }, { skip: !showOvercastLibrary });

    const videoRef = useRef<HTMLVideoElement>(null);
    const fileDropRef = useRef<HTMLFieldSetElement>(null);

    function onFileSelected(file: File) {
        if (!file || !file.type.startsWith("video")) {
            Notifications.notify(
                "Invalid file type selected",
                `The selected file, "${file.name}", is not a valid video file. Type of file: "${file.type}"`,
                "warning",
                "invalidFileSelected",
            );
            return;
        }

        if (file.size < 1000) {
            Notifications.notify(
                "Invalid file selected",
                `The selected file, "${file.name}", is smaller than the minimum size of 1 KB.`,
                "warning",
                "invalidFileSelected",
            );
            return;
        }

        setSelectedFile(file);
        props.dataChanged({ type: "file", file });
    }

    function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];
        onFileSelected(file);
    }

    function handleFileDropHighlight(e: React.DragEvent<HTMLFieldSetElement>) {
        e.preventDefault();
        e.stopPropagation();
        fileDropRef.current.classList.add("highlight");
    }
    function handleFileDropUnhighlight(e: React.DragEvent<HTMLFieldSetElement>) {
        e.preventDefault();
        e.stopPropagation();
        fileDropRef.current.classList.remove("highlight");
    }

    function handleFileDropped(e: React.DragEvent<HTMLFieldSetElement>) {
        handleFileDropUnhighlight(e);
        const file = e.dataTransfer.files?.[0];
        onFileSelected(file);
    }

    function handleOvercastMediaSelected(media: IntegrationMediaSource) {
        props.dataChanged({
            type: "integration",
            integrationMediaSource: media,
        });

        props.validityChanged(true);
    }

    function handleHideOvercastSelection() {
        setSelectedFile(undefined);
        props.validityChanged(false);
        setShowOvercastLibrary(false);
    }

    function renderSelectedSource() {
        return (
            <div className="gap-4 d-flex flex-column justify-content-center align-items-center border-bottom">
                <video
                    className={!isSupported ? "d-none" : ""}
                    style={{ maxHeight: 500, maxWidth: "100%" }}
                    ref={videoRef}
                    controls
                />

                {!isSupported && (
                    <div className="gap-3 p-4 rounded border d-flex flex-column align-items-center w-50 bg-body-tertiary">
                        <FontAwesomeIcon icon={faVideoSlash} className="fs-2" aria-label="No preview available" />
                        <aside className="text-center">
                            The format of the selected video file is not compatible with your browser. No preview
                            available.
                        </aside>
                    </div>
                )}

                <p>
                    File selected: <span className="fw-bold">{selectedFile.name}</span>
                </p>
            </div>
        );
    }

    function handleShowOvercastSelection() {
        setSelectedFile(undefined);
        props.validityChanged(false);
        setShowOvercastLibrary(true);
    }

    function renderOvercastSource(media: IntegrationMediaSource) {
        return (
            <div key={media.integrationMediaId}>
                <div>
                    <img width={250} src={media.preview.source} />
                </div>
                <div>
                    <span>{media.name}</span>
                    <Form.Check
                        key={media.integrationMediaId}
                        type="radio"
                        onChange={() => {
                            handleOvercastMediaSelected(media);
                        }}
                    />
                </div>
            </div>
        );
    }

    function renderOvercastSourceLibrary() {
        return (
            <div className="gap-2 d-flex flex-column">
                <section className="d-flex">
                    <Button
                        variant="outline-secondary"
                        className="m-0 bg-transparent border-0"
                        title="Return to file selection"
                        onClick={handleHideOvercastSelection}
                    >
                        <FontAwesomeIcon icon={faLongArrowLeft} />
                    </Button>

                    <h5 className="m-0 d-flex align-items-center">Overcast Library</h5>
                </section>
                <section>
                    <ConditionalSpinner isLoading={overcastIsLoading}>
                        {overcastIsSuccess ? (
                            overcastData?.map((media) => renderOvercastSource(media))
                        ) : (
                            <BasicAlert message="There was an error loading Overcast data." />
                        )}
                    </ConditionalSpinner>
                </section>
            </div>
        );
    }

    useEffect(() => {
        const video = videoRef?.current;

        if (!video || !selectedFile?.name) {
            props.validityChanged(false);
            return;
        }

        URL.revokeObjectURL(video.src);
        video.src = URL.createObjectURL(selectedFile);
        props.validityChanged(true);

        const handleCanPlay = () => {
            setIsSupported(true);
        };
        const handleError = () => {
            setIsSupported(false);
        };

        video.addEventListener("canplay", handleCanPlay);
        video.addEventListener("error", handleError);

        return () => {
            video?.removeEventListener("canplay", handleCanPlay);
            video?.removeEventListener("error", handleError);
        };
    }, [selectedFile?.name]);

    return (
        <div className="gap-2 d-flex flex-column">
            {showOvercastLibrary ? (
                renderOvercastSourceLibrary()
            ) : (
                <>
                    {isFileSelected && renderSelectedSource()}

                    <fieldset
                        className="gap-2 mb-1 text-center drop-file d-flex flex-column align-items-center"
                        ref={fileDropRef}
                        onDragEnter={handleFileDropHighlight}
                        onDragOver={handleFileDropHighlight}
                        onDragLeave={handleFileDropUnhighlight}
                        onDrop={handleFileDropped}
                    >
                        <legend className="visually-hidden">Media source upload</legend>

                        {!isFileSelected && <FontAwesomeIcon size="8x" icon={faCloudArrowUp} />}

                        <p className="p-0 m-0">Drag and Drop Video File</p>

                        <div className="text-muted">or</div>

                        <Form.Label className="mt-1 btn btn-secondary">
                            <Form.Control
                                // Specific file formats also listed because some files fail to be displayed on Chromium.
                                // Users _can_ work around this by selecting "all file types" or similar in their file explorer,
                                // but it would be ideal to just have all relevant files show up as selectable by default.
                                // NOTE: may need more file extensions specified here in the future
                                accept="video/*, .avi, .mkv, .wmv, .qt, .flv, .swf"
                                type="file"
                                onChange={handleFileSelected}
                                hidden
                                aria-hidden
                            />
                            Browse Files...
                        </Form.Label>

                        {/* <Button onClick={handleShowOvercastSelection}>Select From Overcast Library</Button> */}
                    </fieldset>
                </>
            )}
        </div>
    );
}

export default SourceSelectionPage;
