import { useEffect, useMemo, useState } from "react";
import { Accordion, Button, Form, Modal, Table } from "react-bootstrap";
import Select from "react-select";
import { VizContext } from "../../../models/viz/operations/DataOperation";
import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import VideoClip, { VideoClipRequest, VideoClipResolutionPreserveOptions } from "../../../models/media/VideoClip";
import { capitaliseFirst, tryExtractErrorMessage } from "../../../utilities/helpers";
import { kinesenseApiSlice, useAddVideoClipMutation, useGetMediaSourceQuery } from "../../../store/api/kinesense";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./CreateClipModal.scss";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import OverlaySpinner from "../../OverlaySpinner";
import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
import { Tasks } from "../../../utilities/Tasks/Tasks";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../../models/viz/dataSources/DataSource";
import useNumberForInput from "../../../hooks/useNumberForInput";

const MIN_PIXELS = 50;

const PRESERVE_RES_OPTIONS = VideoClipResolutionPreserveOptions.map((o) => ({ value: o, label: capitaliseFirst(o) }));
type PreserveResOption = (typeof PRESERVE_RES_OPTIONS)[number];

const CUSTOM_RES_OPTIONS = [
    { value: [720, 576], label: "SD (720 x 576)" },
    { value: [1280, 720], label: "HD / 720p (1280 x 720)" },
    { value: [1920, 1080], label: "FHD / 1080p (1920 x 1080)" },
];
type CustomResOption = (typeof CUSTOM_RES_OPTIONS)[number];

type AccordionKey = "basic" | "advanced";

export interface CreateClipModalProps {
    context: VizContext;
}

const CreateClipModal = NiceModal.create((props: CreateClipModalProps) => {
    const dispatch = useDispatch();

    const { view, projectId } = props.context;
    const { cursor, secondaryCursor } = view;

    const modal = useModal();
    const mediaId = (view.data.hasAssociatedMedia && view.data.primaryMediaId) || undefined;

    const { data: mediaSource } = useGetMediaSourceQuery({ projectId, mediaId }, { skip: !projectId || !mediaId });
    const [requestCreateClip] = useAddVideoClipMutation();

    const initialDisplay = mediaSource.files.initialDisplay;
    const nativeWidth = initialDisplay?.width ?? 0;
    const nativeHeight = initialDisplay?.height ?? 0;

    const [isSendingRequest, setIsSendingRequest] = useState(false);

    // Start and end labels based on primary and secondary cursors
    const [startLabel, endLabel] = useMemo(
        () => [
            dayjs(Math.min(cursor, secondaryCursor)).format(DateFormats.dayMonthYearWithTimeSeconds),
            dayjs(Math.max(cursor, secondaryCursor)).format(DateFormats.dayMonthYearWithTimeSeconds),
        ],
        [cursor, secondaryCursor],
    );

    // Accordion for choosing basic/advanced custom resolution
    const [accordionActiveKey, setAccordionActiveKey] = useState<AccordionKey>("basic");
    function onSelectActiveKey(e: string) {
        if (!e) {
            return;
        }
        setAccordionActiveKey(e as AccordionKey);
    }

    // Base options
    const [useNativeRes, setUseNativeRes] = useState(true);
    const [targetResPreserve, setTargetResPreserve] = useState<PreserveResOption>(PRESERVE_RES_OPTIONS[0]);
    const isPreservingHeight = targetResPreserve.value == "height";
    const isPreservingWidth = targetResPreserve.value == "width";

    // Basic custom resolution
    const [basicCustomRes, setBasicCustomRes] = useState<CustomResOption>(CUSTOM_RES_OPTIONS[0]);

    // Advanced custom resolution
    const [targetRes, setTargetRes] = useState<{ width: number; height: number }>({
        width: nativeWidth,
        height: nativeHeight,
    });

    // Separate values for the width / height in the input controls for more ergonomic editing of values
    const [inputWidth, setInputWidth, onChangeInputWidth] = useNumberForInput(nativeWidth);
    const [inputHeight, setInputHeight, onChangeInputHeight] = useNumberForInput(nativeHeight);

    const isResIndivisibleByTwo = inputWidth % 2 !== 0 || inputHeight % 2 !== 0;
    const isInvalidRes =
        inputHeight <= MIN_PIXELS ||
        inputWidth <= MIN_PIXELS ||
        inputHeight != targetRes.height ||
        inputWidth != targetRes.width ||
        isResIndivisibleByTwo;

    // Sync advanced custom resolutions input width/height with state for width/height, when values are valid
    useEffect(() => {
        if (!inputWidth) {
            return;
        }

        let newHeight = targetRes.height;
        if (isPreservingHeight) {
            const ratio = Math.abs(inputWidth / nativeWidth);
            newHeight = Math.round(nativeHeight * ratio);
            // Ensure divisible by 2
            if (newHeight % 2 !== 0) {
                newHeight -= 1;
            }
        }

        if (newHeight) {
            setInputHeight(newHeight);
            setTargetRes({ width: inputWidth, height: newHeight });
        }
    }, [inputWidth]);
    useEffect(() => {
        if (!inputHeight) {
            return;
        }

        let newWidth = targetRes.width;
        if (isPreservingWidth) {
            const ratio = Math.abs(inputHeight / nativeHeight);
            newWidth = Math.round(nativeWidth * ratio);
            // Ensure divisible by 2
            if (newWidth % 2 !== 0) {
                newWidth -= 1;
            }
        }

        if (newWidth) {
            setInputWidth(newWidth);
            setTargetRes({ width: newWidth, height: inputHeight });
        }
    }, [inputHeight]);

    function onSubmit(e: React.FormEvent) {
        e.preventDefault();
        setIsSendingRequest(true);

        const payload: VideoClipRequest = {
            startAt: Math.floor(Math.min(cursor, secondaryCursor) - mediaSource.startsAt),
            endAt: Math.floor(Math.max(cursor, secondaryCursor) - mediaSource.startsAt),
        };

        // Set basic/advanced custom resolution
        if (!useNativeRes) {
            switch (accordionActiveKey) {
                case "basic":
                    payload.targetResolution = {
                        width: basicCustomRes.value[0],
                        height: basicCustomRes.value[1],
                        preserve: "height",
                    };
                    break;
                case "advanced":
                    payload.targetResolution = {
                        ...targetRes,
                        preserve: targetResPreserve.value,
                    };
            }
        }

        requestCreateClip({
            mediaId,
            projectId,
            payload,
        })
            .unwrap()
            .then((res) => {
                Notifications.notify(
                    "Video clip is being created",
                    "Video clip creation has been requested successfully. You will receive a notification when it is ready to download.",
                    "info",
                );

                Tasks.addTask(`Creating clip for "${mediaSource.name}"`, {
                    onFinished: async () => {
                        Notifications.notify(
                            "Video clip created successfully",
                            `A video clip for "${mediaSource.name}" was created successfully.`,
                            "important",
                        );
                    },
                    refetch: async () => {
                        const clips: VideoClip[] = await (
                            dispatch(
                                kinesenseApiSlice.endpoints.getVideoClips.initiate(
                                    { projectId, mediaId },
                                    { forceRefetch: true },
                                ),
                            ) as DispatchType
                        ).unwrap();

                        const hasUpdated = clips?.find((c) => c.fileId === res.fileId) !== undefined;
                        return { hasUpdated, hasFinished: hasUpdated };
                    },
                    millisecondsToNextUpdate: (countUnsuccessfulUpdates: number) =>
                        Math.min(60000, Math.max(10000, countUnsuccessfulUpdates ** 2 * 5000)),
                });
            })
            .catch((e) => {
                Notifications.notify(
                    "Error with video clip creation",
                    `The following error was encountered while attempting to request video clip creation:
                    ${tryExtractErrorMessage(e)}`,
                );
            })
            .finally(() => {
                setIsSendingRequest(false);
                modal.hide();
            });
    }

    return (
        <Modal
            fullscreen="lg-down"
            {...bootstrapDialog(modal)}
            centered
            backdrop="static"
            keyboard={false}
            className="create-clip-modal"
        >
            <Form onSubmit={onSubmit}>
                <Modal.Header>
                    <Modal.Title>
                        <div className="h5">Create a Video Clip</div>
                    </Modal.Title>
                </Modal.Header>
                <div className="position-relative">
                    <Modal.Body className="gap-3 d-flex flex-column">
                        <section>
                            <h5>Info</h5>
                            <Table className="m-0 align-middle details-table">
                                <tbody className="px-3 border-none">
                                    <tr>
                                        <th>Media source:</th>
                                        <td className="border-none align-self-center">
                                            <span>{mediaSource.name}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Start at:</th>
                                        <td>{startLabel}</td>
                                    </tr>
                                    <tr>
                                        <th>End at:</th>
                                        <td>{endLabel}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </section>

                        <section>
                            <h5>Options</h5>
                            <Form.Group className="gap-2 mb-1 d-flex">
                                <Form.Check
                                    type="checkbox"
                                    checked={useNativeRes}
                                    onChange={() => setUseNativeRes(!useNativeRes)}
                                />
                                <Form.Label>
                                    Use native resolution ({nativeWidth}x{nativeHeight})
                                </Form.Label>
                            </Form.Group>

                            <fieldset disabled={useNativeRes}>
                                <Accordion activeKey={accordionActiveKey} onSelect={onSelectActiveKey}>
                                    <Accordion.Item eventKey={"basic" as AccordionKey}>
                                        <Accordion.Header title="Select a custom resolution from a list of common video resolutions">
                                            Custom Resolution
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Select
                                                classNamePrefix="react-select"
                                                value={basicCustomRes}
                                                options={CUSTOM_RES_OPTIONS}
                                                onChange={setBasicCustomRes}
                                            />
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey={"advanced" as AccordionKey}>
                                        <Accordion.Header title="Set a user-defined custom resolution">
                                            Advanced Custom Resolution
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="gap-2 px-4 d-flex flex-column">
                                                <Form.Group
                                                    title="Property of resolution to preserve (by ratio)"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Preserve resolution</Form.Label>

                                                    <Select
                                                        classNamePrefix="react-select"
                                                        value={targetResPreserve}
                                                        options={PRESERVE_RES_OPTIONS}
                                                        onChange={setTargetResPreserve}
                                                    />
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.Label>Target resolution</Form.Label>
                                                    <div className="gap-2 align-items-center advanced-res-inputs">
                                                        <Form.Control
                                                            title="Desired width of new clip"
                                                            isInvalid={isInvalidRes}
                                                            disabled={isPreservingWidth}
                                                            type="text"
                                                            value={useNativeRes ? nativeWidth : inputWidth}
                                                            onChange={onChangeInputWidth}
                                                        />

                                                        <FontAwesomeIcon icon={faXmark} />

                                                        <Form.Control
                                                            title="Desired height of new clip"
                                                            isInvalid={isInvalidRes}
                                                            disabled={isPreservingHeight}
                                                            type="text"
                                                            value={useNativeRes ? nativeHeight : inputHeight}
                                                            onChange={onChangeInputHeight}
                                                        />

                                                        <Form.Control.Feedback type="invalid" className="w-100">
                                                            Please ensure that the provided resolution's height and
                                                            width are greater than {MIN_PIXELS} pixels, and that both
                                                            values are divisible by 2.
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </fieldset>
                        </section>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={modal.hide} variant="secondary">
                            Cancel
                        </Button>

                        <Button disabled={isSendingRequest} variant="success" type="submit">
                            {isSendingRequest ? "Sending request..." : "Create Video Clip"}
                        </Button>

                        <OverlaySpinner isLoading={isSendingRequest} />
                    </Modal.Footer>
                </div>
            </Form>
        </Modal>
    );
});

export default CreateClipModal;
