import { ListGroup } from "react-bootstrap";
import ConditionalSpinner from "../../../components/ConditionalSpinner";
import { useGetProjectQuery, useGetProjectThumbnailsQuery } from "../../../store/api/kinesense";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import ReviewVideoListItem from "./ReviewVideoListItem";
import { SetStateAction, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import useMediaSources from "../../../hooks/useMediaSources";

export interface ReviewVideoListProps {
    viewId: string;
    mediaId: string;
    setMediaId: (_: SetStateAction<string>) => void;
    isExpanded: boolean;
    setIsExpanded: (_: React.SetStateAction<boolean>) => void;
}

function ReviewVideoList(props: ReviewVideoListProps) {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);

    const { data: project } = useGetProjectQuery({ projectId }, { skip: projectId === undefined });
    const projectName = project?.name ?? "Unknown Project";

    const { allMediaSources, hasLoadedMediaSources } = useMediaSources(props.viewId);
    const {
        data: thumbnails,
        isSuccess,
        isFetching,
        refetch,
    } = useGetProjectThumbnailsQuery({ projectId }, { skip: projectId === undefined });
    const hasLoadedThumbnails = isSuccess && !isFetching;

    // Re-fetch thumbnails before the signed URLs expire
    useEffect(() => {
        if (!thumbnails?.expiresAt) {
            return;
        }

        const now = Date.now();
        const expiresIn = Math.max((thumbnails.expiresAt - now) / 2);
        if (expiresIn <= 0) {
            console.error(`Invalid thumbnail expiration time: ${expiresIn}ms`);
            return;
        }
        const handle = setTimeout(refetch, expiresIn);
        return () => {
            clearTimeout(handle);
        };
    }, [thumbnails?.expiresAt]);

    function toggleSidebar() {
        props.setIsExpanded((curr) => !curr);
    }

    return (
        <div className="position-relative video-sidebar">
            <div className="gap-2 py-2 px-3 review-video-list-inner-container d-flex flex-column">
                <ConditionalSpinner isLoading={!hasLoadedMediaSources}>
                    <header className="gap-2 pt-2 ps-2 d-flex align-items-end">
                        <h2 className="m-0 fs-4">{projectName}</h2>
                        <span className="fst-italic text-muted">{allMediaSources?.length ?? 0} videos</span>
                    </header>

                    <ListGroup variant="flush" className="gap-2 d-flex flex-column">
                        {allMediaSources?.map((data) => {
                            const thumbnail = thumbnails?.thumbnails?.[data.mediaId];
                            return (
                                <ReviewVideoListItem
                                    key={data.mediaId}
                                    data={data}
                                    viewId={props.viewId}
                                    activeMediaId={props.mediaId}
                                    setActiveMediaId={props.setMediaId}
                                    thumbnail={thumbnail}
                                    hasLoadedThumbnails={hasLoadedThumbnails}
                                />
                            );
                        })}
                    </ListGroup>
                </ConditionalSpinner>
            </div>

            <button className="pt-2 video-sidebar-expand-button" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={props.isExpanded ? faAngleDoubleLeft : faAngleDoubleRight} />
            </button>
        </div>
    );
}

export default ReviewVideoList;
