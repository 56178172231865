import VideoClip from "../../../../models/media/VideoClip";
import { Notifications } from "../../../../utilities/Notifications/Notifications";
import { tryExtractErrorMessage } from "../../../../utilities/helpers";
import * as AnalyticsStore from "../../../../store/analytics/Analytics";
import { useDispatch } from "react-redux";
import { useLazyGetStoredFileQuery } from "../../../../store/api/kinesense";
import dayjs from "dayjs";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateFormats } from "../../../../utilities/dates";
import { ReviewVideoListItemTrayContentProps } from "./ReviewVideoListItemFooter";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

export type ReviewVideoListItemClipProps = ReviewVideoListItemTrayContentProps & {
    clip: VideoClip;
};

export default function ReviewVideoListItemClip(props: ReviewVideoListItemClipProps) {
    const dispatch = useDispatch();

    const [requestStoredFile] = useLazyGetStoredFileQuery();
    const [isDownloadingFile, setIsDownloadingFile] = useState(false);

    async function downloadClip(fileId: string) {
        setIsDownloadingFile(true);

        try {
            const storedFile = await requestStoredFile({ fileId: props.clip.fileId }).unwrap();
            const resp = await fetch(storedFile.accessUrl.url);
            const url = URL.createObjectURL(await resp.blob());
            const a = document.createElement("a");
            a.href = url;
            a.download = `${props.data.name}_clip_${fileId}`;
            a.click();

            URL.revokeObjectURL(url);
        } catch (e) {
            Notifications.notify(
                "Failed to download clip",
                `The following error was encountered while attempting to download a clip: ${tryExtractErrorMessage(e)}`,
            );
        }

        setIsDownloadingFile(false);
    }

    function jumpToTime(startOffset: number) {
        dispatch(
            AnalyticsStore.actionCreators.setCursorPosition(
                props.viewId,
                "primary",
                props.data.startsAt + startOffset,
                "reviewVideoListClips",
            ),
        );
    }

    return (
        <tr>
            <td>
                <a
                    title="Jump to clip start time"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                        e.preventDefault();
                        jumpToTime(props.clip.startAt);
                    }}
                >
                    {dayjs(props.data.startsAt + props.clip.startAt).format(DateFormats.dayMonthYearWithTimeSeconds)}
                </a>
            </td>
            <td>{dayjs.duration(props.clip.endAt - props.clip.startAt).humanize()}</td>
            <td>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        downloadClip(props.clip.fileId);
                    }}
                    className="button-with-icon"
                    disabled={isDownloadingFile}
                >
                    {isDownloadingFile ? <Spinner size="sm" /> : <FontAwesomeIcon icon={faDownload} />}
                </button>
            </td>
        </tr>
    );
}
