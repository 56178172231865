import { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import dayjs, { Dayjs } from "dayjs";
import { Task } from "./Tasks";
import { DateFormats } from "../dates";
import { Placeholder } from "react-bootstrap";
import useInterval from "../../hooks/useInterval";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const TIME_AGO_DEFAULT = "< 1 minute" as const;

interface TaskItemPRops {
    task: Task;
    onTaskClosed: (task: Task) => void;
}

function getFromNow(addedAt: Dayjs): string {
    const fromNow = addedAt.fromNow(true);

    // Use default label for time less than 1 minute
    if (fromNow.includes("second")) {
        return TIME_AGO_DEFAULT;
    }

    return fromNow;
}

export function TaskItem(props: TaskItemPRops) {
    const addedAt = dayjs(props.task.addedAt);
    const [unsuccessfulUpdates, setUnsuccessfulUpdates] = useState(0);
    const callbacks = props.task.callbacks;

    function incrementUnsuccessfulUpdates() {
        setUnsuccessfulUpdates((prev) => prev + 1);
    }

    const { pauseInterval } = useInterval(
        () => {
            if (props.task.isFinished) {
                return;
            }

            callbacks
                .refetch()
                .then(({ hasUpdated, hasFinished }) => {
                    if (hasFinished) {
                        pauseInterval();
                        props.task.finish();
                    } else if (hasUpdated) {
                        setUnsuccessfulUpdates(0);
                    } else {
                        incrementUnsuccessfulUpdates();
                    }
                })
                .catch(incrementUnsuccessfulUpdates);
        },
        callbacks?.millisecondsToNextUpdate(unsuccessfulUpdates) ?? 60000,
        !props.task.isFinished,
    );

    useEffect(() => {
        if (props.task.isFinished) {
            pauseInterval();
        }
    }, [props.task.isFinished]);

    return (
        <Toast
            className={`w-100 overflow-hidden border-bottom-0 task-toast${
                !props.task.isFinished ? " active-task" : ""
            }`}
            onClose={() => props.onTaskClosed(props.task)}
        >
            <Toast.Header className="overflow-hidden position-relative d-flex justify-content-between">
                <strong>{props.task.title}</strong>
                <small className="gap-1 d-flex align-items-center">
                    {props.task.isFinished ? (
                        <>
                            <FontAwesomeIcon className="text-success" icon={faCheck} />
                            <span className="text-muted">Done</span>
                        </>
                    ) : (
                        <span className="text-muted">In progress</span>
                    )}
                </small>
                {!props.task.isFinished && (
                    <small title={addedAt.format(DateFormats.dayMonthYearWithTimeSeconds)}>{getFromNow(addedAt)}</small>
                )}
                <div className="progress-bar placeholder-glow">
                    {props.task.isFinished ? (
                        <div className="bg-success" role="progressbar" aria-label="complete" />
                    ) : (
                        <Placeholder />
                    )}
                </div>
            </Toast.Header>
        </Toast>
    );
}
