import * as d3 from "d3";
import { MutableRefObject, useRef } from "react";
import { useDispatch } from "react-redux";
import { MediaSource } from "../../../models/media/MediaSource";
import {
    AnalyticsStateView,
    SetSecondaryViewCursorPositionAction,
    SetViewCursorPositionAction,
} from "../../../store/analytics/Analytics";
import useTimelineMarkers from "./useTimelineMarkers";

function useTimelineEvents(
    view: AnalyticsStateView,
    viewId: string,
    media: MediaSource,
    timelineId: MutableRefObject<string>,
    timelineRef: MutableRefObject<SVGSVGElement | undefined>,
    videoRef: MutableRefObject<HTMLVideoElement>,
    xScale: MutableRefObject<d3.ScaleTime<number, number, never> | undefined>,
    domainOffset: MutableRefObject<number>,
    secondsPerPixel: MutableRefObject<number>,
    totalWidth: MutableRefObject<number>,
    drawTimeline: (centrePointOverride?: number) => void,
) {
    const dispatch = useDispatch();

    const isSelecting = useRef(false);
    const isSelectionMouseDown = useRef(false);
    const isPanningMouseDown = useRef(false);
    const lastPanningMouseX = useRef(0);

    const { cursorMarker, secondaryCursorMarker, hoverMarker, hoverText, selection, updateCursors, setMarkers } =
        useTimelineMarkers(timelineRef, view?.cursor, view?.secondaryCursor, xScale);

    function handleMouseMove(e: MouseEvent) {
        const timeline = d3.select(timelineRef.current);
        const mouse = d3.pointer(e, timeline.node());

        hoverMarker.current.setPosition(mouse[0]);
        const date = xScale.current.invert(mouse[0]);
        hoverText.current.setDate(date);
        hoverText.current.setPosition(mouse[0], totalWidth.current);

        if (isSelectionMouseDown.current) {
            handleSelecting(mouse[0]);
        }

        if (isPanningMouseDown.current) {
            handlePanning(mouse[0]);
        }
    }

    function handleMouseDown(e: MouseEvent) {
        const timeline = d3.select(timelineRef.current);
        const mouse = d3.pointer(e, timeline.node());

        if (e.button === 0) {
            startSelecting(mouse[0]);
        }

        if (e.button === 1) {
            startPanning(mouse[0]);
            e.preventDefault();
            e.stopPropagation();
        }
    }

    function handleMouseUp(e: MouseEvent) {
        if (e.button === 0) {
            endSelecting();
        }

        if (e.button === 1) {
            endPanning();
        }
    }

    function handleMouseLeave(e: MouseEvent) {
        e.preventDefault();
        isSelecting.current = false;
    }

    function handleMouseWheel(e: WheelEvent) {
        let centrePointOverride: number;
        // This allows zooming in/out at the section that the user is hovering over
        if (hoverMarker.current?.position) {
            centrePointOverride = hoverMarker.current.position / totalWidth.current;
        }

        e.deltaY > 0 ? zoomOut(centrePointOverride) : zoomIn(centrePointOverride);
        e.preventDefault();
        e.stopPropagation();
    }

    function handleSelecting(mousePosition: number) {
        if (mousePosition != view.cursor) {
            setCursorPosition(mousePosition);
        }

        if (view.mediaStates.play == "playing") {
            endSelecting();
            return;
        }

        if (!isSelecting.current) {
            setSecondaryCursorPosition(mousePosition);
        }

        isSelecting.current = true;

        selection.current.update();
        selection.current.show();
    }

    function handlePanning(mousePosition: number) {
        const previous = xScale.current.invert(lastPanningMouseX.current).getTime();
        const current = xScale.current.invert(mousePosition).getTime();
        lastPanningMouseX.current = mousePosition;
        domainOffset.current = previous - current;

        drawTimeline();
        updateCursors();
    }

    function startSelecting(mousePosition: number) {
        setCursorPosition(mousePosition);
        cursorMarker.current.show();

        selection.current.hide();
        clearSecondaryCursorPosition();

        isSelectionMouseDown.current = true;
    }

    function endSelecting() {
        isSelectionMouseDown.current = false;
        isSelecting.current = false;
    }

    function startPanning(mousePosition: number) {
        lastPanningMouseX.current = mousePosition;
        isPanningMouseDown.current = true;
        hoverText.current.hide();
    }

    function endPanning() {
        isPanningMouseDown.current = false;
        hoverText.current.show();
    }

    function zoomIn(centrePointOverride?: number) {
        if (secondsPerPixel.current > 0.01) {
            secondsPerPixel.current /= 1.1;
            drawTimeline(centrePointOverride);
            updateCursors();
        }
    }

    function zoomOut(centrePointOverride?: number) {
        if (secondsPerPixel.current < 1000) {
            secondsPerPixel.current *= 1.1;
            drawTimeline(centrePointOverride);
            updateCursors();
        }
    }

    function dispatchCursorAction(
        type: "analytics/setSecondaryViewCursor" | "analytics/setViewCursor",
        cursorPosition: number | undefined,
    ) {
        const action: SetViewCursorPositionAction | SetSecondaryViewCursorPositionAction = {
            type,
            payload: {
                cursorPosition,
                viewId: viewId,
                updateSource: timelineId.current,
            },
        };

        dispatch(action);
    }

    function setCursorPosition(position: number) {
        cursorMarker.current.setPosition(position);
        const positionMilliseconds = xScale.current.invert(position).getTime();

        // Update video element's time too to avoid more updates from the VideoPlayer component
        if (videoRef.current) {
            videoRef.current.currentTime = (positionMilliseconds - media.startsAt) / 1000;
        }

        dispatchCursorAction("analytics/setViewCursor", positionMilliseconds);
    }

    function setSecondaryCursorPosition(position: number) {
        if (!secondaryCursorMarker.current) {
            return;
        }
        secondaryCursorMarker.current.show();
        if (secondaryCursorMarker.current.position != position) {
            secondaryCursorMarker.current.setPosition(position);
            dispatchCursorAction("analytics/setSecondaryViewCursor", xScale.current.invert(position).getTime());
        }
    }

    function clearSecondaryCursorPosition() {
        secondaryCursorMarker.current.hide();
        if (view.secondaryCursor !== undefined) {
            dispatchCursorAction("analytics/setSecondaryViewCursor", undefined);
        }
    }

    function setListeners() {
        const timeline = d3.select(timelineRef.current);
        timeline.on("mousemove", handleMouseMove);
        timeline.on("mousedown", handleMouseDown);
        timeline.on("mouseup", handleMouseUp);
        timeline.on("mouseleave", handleMouseLeave);
        timeline.on("wheel", handleMouseWheel);
    }

    function onTimelineMouseLeave() {
        if (hoverMarker.current == undefined || hoverText.current == undefined) {
            return;
        }

        endSelecting();
        endPanning();
        hoverMarker.current.hide();
        hoverText.current.hide();
    }

    function onTimelineMouseEnter() {
        if (hoverMarker.current == undefined) {
            return;
        }

        hoverMarker.current.show();

        if (!isPanningMouseDown.current) {
            hoverText.current.show();
        }
    }

    return {
        setListeners,
        onTimelineMouseEnter,
        onTimelineMouseLeave,
        cursorMarker,
        updateCursors,
        setMarkers,
    };
}

export default useTimelineEvents;
