import { SetStateAction } from "react";
import { createRoot } from "react-dom/client";
import { PercentCrop } from "react-image-crop";

type SetIsSelectingFn = (_: SetStateAction<boolean>) => void;
type SetComponentCropFn = (_: SetStateAction<PercentCrop>) => void;

export class VideoImageSelector {
    public isSelecting: boolean = false;
    private setIsSelecting: SetIsSelectingFn;
    private crop: PercentCrop;
    private componentContainer: HTMLDivElement | undefined;
    private componentRenderer: React.FC | undefined;
    public setComponentCrop: SetComponentCropFn;

    constructor() {
        this.endSelection = this.endSelection.bind(this);
    }

    public getCropSelectionComponent() {
        return document.getElementsByClassName("ReactCrop__crop-selection")[0];
    }

    public renderComponent() {
        const el = this.getCropSelectionComponent();
        if (!this.componentRenderer || !el) {
            return;
        }

        // Create component container
        const div = document.createElement("div");
        div.className = "ReactCrop__selection-options";
        this.componentContainer = div;

        // Render component container
        const root = createRoot(div);
        root.render(<this.componentRenderer />);

        // Append component to the selection element for relative placement
        el.replaceChildren(el.children[0]);
        el.appendChild(div);
    }

    /** Update the position of the component to avoid overflowing the video player where possible. */
    public updatePosition() {
        const el = this.getCropSelectionComponent();
        // Get the parent of the `ReactCrop` component, which should be video player. This allows checking
        // against the full height of the player rather than just the video itself
        const parent = el?.parentElement?.parentElement?.parentElement;
        if (!this.componentRenderer || !this.componentContainer || !parent) {
            return;
        }

        const { y: parentY, height: parentHeight } = parent.getBoundingClientRect();
        const { y: elY, height: elHeight } = el.getBoundingClientRect();
        const { height } = this.componentContainer.getBoundingClientRect();
        const spacingPx = 10;

        // Move to the top of the selection component if the component would overflow the parent
        if (elY + elHeight + height + spacingPx > parentY + parentHeight) {
            this.componentContainer.classList.add("top");
        } else {
            this.componentContainer.classList.remove("top");
        }
    }

    public beginSelection(componentRenderer: React.FC, initialCrop?: PercentCrop) {
        if (initialCrop !== undefined) {
            this.setComponentCrop(initialCrop);
        }

        this.componentRenderer = componentRenderer;
        this.setIsSelecting(true);
    }
    public endSelection() {
        this.setIsSelecting(false);
        this.componentRenderer = undefined;
        this.componentContainer = undefined;
    }
    public completeSelection() {
        this.endSelection();
        return this.crop;
    }

    /** Only to be used by the crop component, use `setComponentCrop` to actually modify the crop */
    public setCrop(crop: PercentCrop) {
        this.crop = crop;
    }

    /** Only to be used by the crop component */
    public register(setIsSelecting: SetIsSelectingFn, setComponentCrop: SetComponentCropFn) {
        this.setIsSelecting = setIsSelecting;
        this.setComponentCrop = setComponentCrop;
    }
}

export const GlobalVideoImageSelector = new VideoImageSelector();
