import { AreaBounds } from "cloud-core/spatial/Spatial";
import { PercentCrop } from "react-image-crop";

/** Capitalises the first character of a given string */
export function capitaliseFirst(input: string) {
    if (input === undefined || input.length === 0) {
        return input;
    }

    return input.charAt(0).toUpperCase() + input.slice(1);
}

/** Returns true if number is in the range of the given limits. Note that the lower limit
 * is inclusive and the upper limit is exclusive (not greedy). */
export function isInRange(subject: number, limits: [number, number]) {
    limits.sort((a, b) => a - b); // ensure correct order for lower limit and upper limit

    return subject >= limits[0] && subject < limits[1];
}

/** Takes a string as input and returns a string that is valid for a HTML id by replacing any spaces / symbols with "-" */
export function cleanId(input: string) {
    return input.replace(/\W+/g, "-");
}

/** Throws an error if an AreaBounds object is undefined, null, or does not have the correct number of elements */
export function assertBoundsValid(bounds: AreaBounds | undefined) {
    if (!bounds || bounds.length != 4) {
        throw new Error(`The object passed is not a valid AreaBounds. Object: ${bounds}`);
    }
}

/** Returns true if the given AreaBounds object is ordered correctly i.e. x1 <= x2 and y1 <= y2  */
export function isBoundsElementsValid(bounds: AreaBounds) {
    return bounds[0] < bounds[2] && bounds[1] < bounds[3];
}

/** Converts a `PercentCrop` object to an AreaBounds */
export function convertCropToBounds(crop: PercentCrop): AreaBounds {
    return [crop.x / 100, crop.y / 100, (crop.x + crop.width) / 100, (crop.y + crop.height) / 100];
}

/** Attempts to extract an error message from different error types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tryExtractErrorMessage(e: any): string {
    return e?.data?.message ?? e?.message ?? e?.error ?? "Unknown error";
}

/** Convert a given count of bytes into a human-readable string representation. e.g. 1.2 MB */
export function formatBytes(bytesCount: number) {
    if (bytesCount < 1000) {
        return `${bytesCount} B`;
    } else if (bytesCount < 1_000_000) {
        return `${(bytesCount / 1000).toFixed(1)} KB`;
    } else if (bytesCount < 1_000_000_000) {
        return `${(bytesCount / 1_000_000).toFixed(1)} MB`;
    } else if (bytesCount < 1_000_000_000_000) {
        return `${(bytesCount / 1_000_000_000).toFixed(1)} GB`;
    } else {
        return `${(bytesCount / 1_000_000_000_000).toFixed(1)} TB`;
    }
}

/** Find the closest element in an array to a given target value, using a binary search.
 *
 * WARNING: The array MUST already be sorted by the desired value, otherwise this function will return incorrect results.
 *
 * NOTE: When 2 options are equi-distant from the goal, prefers the lower value.
 *
 * @param arr A sorted array of elements. Must be sorted by the relevant result of `toNumber`.
 * @param target A target for which this function will find the closest value from the array.
 * @param toNumber A function which converts an element from the array into a number. An example would likely be a simple lambda to select a property from an object.
 *
 * @returns {[T, number] | undefined} A tuple with the closest element and its index, or `undefined` if the array was empty.
 * */
export function findClosest<T>(arr: T[], target: number, toNumber: (_: T) => number): [T, number] {
    if (!arr.length) {
        return undefined;
    }

    let start = 0;
    let end = arr.length - 1;

    while (end - start > 1) {
        // Calculate midpoint and compare against the goal
        const mid = Math.floor((start + end) / 2);
        if (toNumber(arr[mid]) == target) {
            return [arr[mid], mid];
        }
        if (toNumber(arr[mid]) < target) {
            start = mid;
        } else {
            end = mid;
        }
    }

    return target - toNumber(arr[start]) <= toNumber(arr[end]) - target ? [arr[start], start] : [arr[end], end];
}
