import { Table } from "react-bootstrap";
import { useGetAllTagEventsQuery } from "../../../../store/api/kinesense";
import { ReviewVideoListItemTrayContentProps } from "./ReviewVideoListItemFooter";
import { faCar, faCube, faDog, faUser, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";

export default function ReviewVideoListItemTags(props: ReviewVideoListItemTrayContentProps) {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);

    const { data, isSuccess, isFetching } = useGetAllTagEventsQuery(
        { projectId },
        { skip: !projectId || !props.data.mediaId },
    );
    const isLoadingTagEvents = isFetching || !isSuccess;

    const tagEvents = (data ?? []).filter((event) => {
        for (const occurrence of event.occurrences) {
            if (occurrence.mediaId === props.data?.mediaId) {
                return true;
            }
        }

        return false;
    });

    if (isLoadingTagEvents) {
        return;
    }

    return (
        <div ref={props.contentRef} className="inner">
            <section className="overflow-hidden">
                <h4>Tag Events</h4>
                {tagEvents.length === 0 ? (
                    <p className="text-center text-muted">No tag events added for this media source</p>
                ) : (
                    <Table borderless className="details-table">
                        <tbody>
                            {tagEvents.map((t) => {
                                const primarySubject = t.subjects[0];
                                if (!primarySubject) {
                                    return undefined;
                                }

                                let icon = faCube;
                                switch (primarySubject.type) {
                                    case "person":
                                        icon = faUser;
                                        break;
                                    case "group":
                                        icon = faUsers;
                                        break;
                                    case "vehicle":
                                        icon = faCar;
                                        break;
                                    case "animal":
                                        icon = faDog;
                                }

                                return (
                                    <tr key={t.eventId} title={t.comments}>
                                        <td>
                                            <FontAwesomeIcon icon={icon} />
                                        </td>
                                        <td className="w-100">{primarySubject.name}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </section>
        </div>
    );
}
