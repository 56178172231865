import { useGetVideoClipsQuery } from "../../../store/api/kinesense";

/** Get the number of video clips associated with a given media ID.
 *
 * Returns undefined while the clips are not yet loaded. */
export default function useClipsCount(projectId: string, mediaId: string): number | undefined {
    const { data: clips, isSuccess: hasLoadedClips } = useGetVideoClipsQuery(
        { projectId, mediaId },
        { skip: !projectId || !mediaId },
    );

    if (!hasLoadedClips) {
        return undefined;
    }

    return clips.length;
}
