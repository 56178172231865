import { UserState } from "../../../../store/user/User";
import { addAuthHeaders } from "../../../../utilities/Auth";

export interface UploadUtilOptions {
    file: File;
    onStart: undefined | (() => void);
    onUploadInitialized: undefined | ((InitializeUploadResponse) => void);
    onProgress: undefined | ((percent: number) => void);
    onComplete: undefined | (() => void);
    onError: undefined | ((err: Error) => void);
    getUser: () => UserState;
}

interface InitializeUploadResponse {
    uploadId: string;
    fileKey: string;
    fileId: string;
    partSize: number;
}

interface CreatePartsResponseItem {
    signedUrl: string;
    partNumber: number;
}

type PartMetadata = {
    signedUrl: string;
    partNumber: number;
};

async function handlePromiseResponse(promise: Promise<Response>) {
    const response = await promise;
    const response_json = await response.json();

    if (!response.ok) {
        throw response_json;
    }

    return response_json.data;
}

export class UploadUtil {
    file: File;
    maxActiveConnections = 5;
    onStart: () => void;
    onUploadInitialized: (InitializeUploadResponse) => void;
    onProgress: (percent: number) => void;
    onError: (err: Error) => void;
    onComplete: () => void;
    getUser: () => UserState;

    parts: CreatePartsResponseItem[] = [];
    totalPartsCount = 0;
    uploadedParts: { partNumber: number; etag: string }[] = [];
    activeConnections: { [key: number]: Promise<void> } = [];
    activeConnectionsCount = 0;
    fileDetails: InitializeUploadResponse;
    headers: Headers = new Headers({
        "Content-Type": "text/plain",
    });

    constructor(options: UploadUtilOptions) {
        this.file = options.file;
        this.onStart = options.onStart;
        this.onUploadInitialized = options.onUploadInitialized;
        this.onProgress = options.onProgress;
        this.onComplete = options.onComplete;
        this.onError = options.onError;
        this.getUser = options.getUser;

        addAuthHeaders(this.getUser().authToken, this.headers);
    }

    async start() {
        window.addEventListener("beforeunload", this.onBeforeWindowUnload);
        this.onStart?.();

        this.fileDetails = await this.initializeUpload();
        this.parts = await this.fetchParts(this.fileDetails.fileId);
        this.totalPartsCount = this.parts.length;

        this.onUploadInitialized?.(this.fileDetails);

        this.uploadNextPart(0);
    }

    onBeforeWindowUnload(e: BeforeUnloadEvent) {
        e.preventDefault();
        e.returnValue = "Warning: Upload will be cancelled if you leave this page.";
    }

    async onSendPartStarted() {
        this.uploadNextPart(0);
    }

    async sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async uploadNextPart(retryCount: number) {
        if (this.activeConnectionsCount >= this.maxActiveConnections) {
            console.log("max active connections reached");
            return;
        }

        if (this.totalPartsCount != 0 && this.totalPartsCount == this.uploadedParts.length) {
            await Promise.all(Object.values(this.activeConnections));
            this.finaliseUpload(this.fileDetails.fileId).catch((e) => {
                this.onError?.(e);
            });
            return;
        }

        const partMetadata = this.parts.pop();

        if (this.file && partMetadata) {
            const sentSize = (partMetadata.partNumber - 1) * this.fileDetails.partSize;
            const partData = this.file.slice(sentSize, sentSize + this.fileDetails.partSize);

            this.activeConnections[partMetadata.partNumber - 1] = this.uploadPart(partMetadata, partData)
                .then(() => {
                    this.uploadNextPart(0);
                })
                .catch(async (err) => {
                    if (retryCount < 3) {
                        await this.sleep(2 ** retryCount * 100);
                        this.uploadNextPart(retryCount + 1);
                    } else {
                        this.onError?.(err);
                    }
                });
            this.activeConnectionsCount++;
        }
    }

    async uploadPart(partMetadata: PartMetadata, data: Blob) {
        if (!window.navigator.onLine) {
            throw new Error("No internet connection");
        }

        const options = {
            method: "PUT",
            body: data,
        };

        const response = await fetch(partMetadata.signedUrl, options);

        if (response.status == 200) {
            const etag = response.headers.get("etag").replace(/"/g, "");

            if (etag) {
                this.uploadedParts.push({
                    partNumber: partMetadata.partNumber,
                    etag,
                });

                this.onProgress?.((this.uploadedParts.length / this.totalPartsCount) * 100);

                delete this.activeConnections[partMetadata.partNumber - 1];
                this.activeConnectionsCount--;
            }
        } else {
            throw await response.json();
        }
    }

    async initializeUpload(): Promise<InitializeUploadResponse> {
        const options = {
            method: "POST",
            headers: this.headers,
            body: JSON.stringify({
                fileName: this.file.name,
                fileSize: this.file.size,
            }),
        };

        return handlePromiseResponse(
            fetch(`https://${process.env.REACT_APP_KINESENSE_API_BASE}/initialize-file-upload`, options),
        );
    }

    async fetchParts(fileId: string): Promise<CreatePartsResponseItem[]> {
        const options = {
            method: "POST",
            headers: this.headers,
        };

        return handlePromiseResponse(
            fetch(`https://${process.env.REACT_APP_KINESENSE_API_BASE}/files/${fileId}/create-parts`, options),
        );
    }

    async finaliseUpload(fileId: string): Promise<void> {
        const options = {
            method: "POST",
            headers: this.headers,
            body: JSON.stringify({ parts: this.uploadedParts }),
        };

        const response = await fetch(
            `https://${process.env.REACT_APP_KINESENSE_API_BASE}/files/${fileId}/finalise-upload`,
            options,
        );

        if (!response.ok) {
            throw await response.json();
        }

        window.removeEventListener("beforeunload", this.onBeforeWindowUnload);
        this.onComplete?.();
    }
}
