import { useLazyGetFragmentImageCookiesQuery, useLazyGetStoredFileQuery } from "../../../../store/api/kinesense";
import { useEffect, useRef, useState } from "react";
import { EntityImage, FileVideoFrameEntityImage, ThumbnailEntityImage } from "../../../../models/viz/Entity";
import ThumbnailExtractor from "../../../../utilities/ThumbnailExtractor";
import LoadingLabel from "../../../../components/LoadingLabel";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import useAsyncCallback from "../../../../hooks/useAsyncCallback";

export interface EntityImageDisplayProps {
    entityImage: EntityImage;
    mediaId: string | undefined;
}

export function EntityImageDisplay(props: EntityImageDisplayProps) {
    const organisationId = useSelector((state: ApplicationState) => state.user?.organisationId);

    const [requestImageCookies] = useLazyGetFragmentImageCookiesQuery();
    const [requestStoredFile] = useLazyGetStoredFileQuery();

    const hasNoImage = (props.entityImage !== undefined && !props.entityImage.hasImage()) ?? true;

    const [isLoading, setIsLoading] = useState(!hasNoImage);
    const [isError, setIsError] = useState(false);
    const [thumbnailSrc, setThumbnailSrc] = useState<string | undefined>(undefined);

    // Unset loading state when data for the image has loaded
    const imgRef = useRef<HTMLImageElement>(null);
    useEffect(() => {
        if (!imgRef.current) {
            return;
        }

        imgRef.current.onload = () => setIsLoading(false);
        imgRef.current.onerror = () => {
            setIsLoading(false);
            setIsError(true);
        };
    }, [imgRef.current]);

    /** Generate and set the thumbnail from the given `FileVideoFrameEntityImage` */
    async function setVideoFrameEntityImage(image: FileVideoFrameEntityImage) {
        const videoFile = await requestStoredFile({ fileId: image.fileId }).unwrap();
        if (!videoFile?.accessUrl?.url) {
            throw new Error("No valid URL available for the video file");
        }

        const url = await ThumbnailExtractor.getThumbnail(videoFile.accessUrl.url, image.bounds, image.timestamp);
        setThumbnailSrc(url);
    }

    // Handle different entity image sources
    useAsyncCallback(
        async () => {
            switch (props.entityImage.origin) {
                // Use a specified thumbnail, falling back to generating the thumbnail from the video file if necessary
                case "thumbnail": {
                    try {
                        const cookies = await requestImageCookies({ mediaId: props.mediaId }).unwrap();
                        if (cookies === undefined) {
                            throw new Error("No cookies available");
                        }

                        const imageAsThumbnail = props.entityImage as ThumbnailEntityImage;

                        // HACK: pass cookies in as query parameters to avoid CORS related issues with credentials
                        const data = await fetch(
                            `https://images.${process.env.REACT_APP_KINESENSE_API_BASE.replace(
                                "api.",
                                "",
                            )}/${organisationId}/${imageAsThumbnail.mediaId}/${imageAsThumbnail.runId}/${
                                imageAsThumbnail.fragGroupId
                            }/${imageAsThumbnail.fragmentId}.jpg?Policy=${cookies["CloudFront-Policy"]}&Key-Pair-Id=${
                                cookies["CloudFront-Key-Pair-Id"]
                            }&Signature=${cookies["CloudFront-Signature"]}`,
                        );

                        const blob = await data.blob();
                        const url = URL.createObjectURL(blob);
                        setThumbnailSrc(url);
                    } catch (e) {
                        console.error(e);
                        // Use backup entity image
                        try {
                            if (props.entityImage?.backupImage !== undefined) {
                                await setVideoFrameEntityImage(props.entityImage.backupImage);
                            }
                        } catch (e) {
                            console.error("Error with getting backup image:", e);
                        }
                    }
                    break;
                }

                // Generate a thumbnail from the video file
                case "videoFileFrame":
                    try {
                        await setVideoFrameEntityImage(props.entityImage);
                    } catch (e) {
                        console.error(e);
                    }
                    break;

                // Use a specific image file
                case "imageFile":
                    try {
                        const imageFile = await requestStoredFile({ fileId: props.entityImage.fileId }).unwrap();
                        if (!imageFile?.accessUrl?.url) {
                            throw new Error("No valid URL available for the image file");
                        }
                        setThumbnailSrc(imageFile.accessUrl.url);
                    } catch (e) {
                        console.error(e);
                    }
                    break;
            }
            setIsLoading(false);
        },
        [props.entityImage],
        { skip: hasNoImage },
    );

    return (
        <>
            {isLoading ? (
                <LoadingLabel isLoading={true} />
            ) : hasNoImage || isError || thumbnailSrc === undefined ? (
                <div aria-label="No image available" className="no-image">
                    No image
                </div>
            ) : undefined}

            <img
                ref={imgRef}
                src={thumbnailSrc}
                style={{ height: "100%", maxWidth: "100%", margin: "auto", display: isLoading ? "none" : "block" }}
            />
        </>
    );
}
