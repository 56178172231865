import { AreaBounds } from "cloud-core/spatial/Spatial";
import { Milliseconds } from "../../utilities/numerics";
import { MediaSource } from "../media/MediaSource";
import { DataDescription } from "./dataDescriptions/DataDescription";
import { EntityProperties } from "./dataDescriptions/EntityProperties";
import { EntityProperty } from "./entityProperties/EntityProperty";
import { NumberListEntityProperty } from "./entityProperties/NumberListEntityProperty";
import { DateEntityProperty } from "./entityProperties/DateEntityProperty";
import { StringEntityProperty } from "./entityProperties/StringEntityProperty";

export interface EntitySourceObject {
    id: string;
    object: unknown;
    projectId: string;
    mediaSource: MediaSource | undefined;
    mediaSourceFrameOffset: number | undefined;
    runId: number | undefined;
}

export class Entity {
    id: string;
    properties: { [key: string]: EntityProperty<unknown> };
    sourceObject: EntitySourceObject;
    dataDescription: DataDescription;
    images: EntityImageCollection;

    constructor(init: Partial<Entity>) {
        Object.assign(this, init);
    }

    getTitle(): string {
        return this.dataDescription.infoGenerator.titleGenerator.generateInformation(this);
    }

    getSubtitle(): string {
        return this.dataDescription.infoGenerator.subtitleGenerator.generateInformation(this);
    }

    getStartTimeOr(startTime: Date): Date {
        const property = this.properties[EntityProperties.StartTime.key];

        if (property === undefined) {
            return startTime;
        }

        return (property as DateEntityProperty).value();
    }

    getStartTimeOrElse(startTimeFn: () => Date): Date {
        const property = this.properties[EntityProperties.StartTime.key];

        if (property === undefined) {
            return startTimeFn();
        }

        return (property as DateEntityProperty).value();
    }

    getEndTimeOr(endTime: Date): Date {
        const property = this.properties[EntityProperties.EndTime.key];

        if (property === undefined) {
            return endTime;
        }

        return (property as DateEntityProperty).value();
    }

    getEndTimeOrElse(endTimeFn: () => Date): Date {
        const property = this.properties[EntityProperties.EndTime.key];

        if (property === undefined) {
            return endTimeFn();
        }

        return (property as DateEntityProperty).value();
    }

    getBoundsOr(bounds: AreaBounds): AreaBounds {
        const property = this.properties[EntityProperties.Meta.Bounds.key];

        if (property === undefined) {
            return bounds;
        }

        const propertyBounds = (property as NumberListEntityProperty).value() as AreaBounds;

        if (propertyBounds.length !== 4) {
            return bounds;
        }

        return propertyBounds;
    }

    getBoundsBitmapOr(bounds: string): string {
        const property = this.properties[EntityProperties.Meta.BoundsBitmap.key];

        if (property === undefined) {
            return bounds;
        }

        return (property as StringEntityProperty).value();
    }

    getLowResBoundsBitmapOr(bounds: string): string {
        const property = this.properties[EntityProperties.Meta.LowResBoundsBitmap.key];

        if (property === undefined) {
            return bounds;
        }

        return (property as StringEntityProperty).value();
    }
}

export class FileVideoFrameEntityImage {
    origin = "videoFileFrame" as const;
    timestamp: Milliseconds;
    bounds: AreaBounds;
    fileId: string;

    public hasImage(): boolean {
        return this.fileId !== undefined;
    }

    constructor(init: { timestamp: Milliseconds; bounds: AreaBounds; fileId: string }) {
        Object.assign(this, init);
    }
}

export class FileImageEntityImage {
    origin = "imageFile" as const;
    fileId: string;

    public hasImage(): boolean {
        return this.fileId !== undefined;
    }

    constructor(init: Partial<FileImageEntityImage>) {
        Object.assign(this, init);
    }
}

export class ThumbnailEntityImage {
    origin = "thumbnail" as const;
    mediaId: string;
    runId: number;
    fragGroupId: string;
    fragmentId: string;
    /** Backup entity image to be used when the thumbnail is unavailable. */
    backupImage?: FileVideoFrameEntityImage;

    public hasImage(): boolean {
        return (
            (this.mediaId !== undefined &&
                this.runId !== undefined &&
                this.fragGroupId !== undefined &&
                this.fragmentId !== undefined) ||
            this.backupImage?.hasImage()
        );
    }
    constructor(init: Partial<ThumbnailEntityImage>) {
        Object.assign(this, init);
    }
}

export type EntityImage = FileVideoFrameEntityImage | FileImageEntityImage | ThumbnailEntityImage;

export class EntityImageCollection {
    images: EntityImage[];
    mainImageSelector: (images: EntityImageCollection) => EntityImage;

    constructor(
        images: EntityImage[] = [],
        mainImageSelector: (images: EntityImageCollection) => EntityImage = (
            imageCollection: EntityImageCollection,
        ) => {
            return imageCollection.images[0];
        },
    ) {
        this.images = images;
        this.mainImageSelector = mainImageSelector;
    }

    getMainImage(): EntityImage {
        return this.mainImageSelector(this);
    }
}
