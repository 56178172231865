import { Form } from "react-bootstrap";
import { Analyser, AnalyticsDefinition } from "../../../../../models/analysis/Config";
import { AlgorithmOptionsData, AlgorithmPageData, ImportWizardPagePropsBase } from "../ImportWizard";
import { useEffect, useState } from "react";
import AlgorithmOptions from "./AlgorithmOptions";

interface AlgorithmSelectionPageProps extends ImportWizardPagePropsBase {
    value: AlgorithmPageData | undefined;
    analyserConfig: AnalyticsDefinition;
    previewVidUrl: string | undefined;
    previewImgUrl: string | undefined;
}

function AlgorithmSelectionPage(props: AlgorithmSelectionPageProps) {
    const [selectedAlgorithm, setSelectedAlgorithm] = useState<Analyser | undefined>(
        props.value ?? props.analyserConfig.analysers[0],
    );

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedId = e.target.value;
        const selected = props.analyserConfig.analysers.find((analyser) => analyser.analyserId === selectedId);
        setSelectedAlgorithm(selected);
    };

    useEffect(() => {
        props.validityChanged(true);
    }, []);

    useEffect(() => {
        props.dataChanged({ ...props.value, ...selectedAlgorithm });
    }, [selectedAlgorithm]);

    return (
        <div>
            <ul className="list-unstyled">
                {props.analyserConfig.analysers.map((analyser, i) => (
                    <li className="mb-3" key={i}>
                        <Form.Check id={analyser.analyserId} type="radio">
                            <Form.Check.Input
                                type="radio"
                                value={analyser.analyserId}
                                name="algo"
                                onChange={handleRadioChange}
                                checked={analyser.analyserId === selectedAlgorithm.analyserId}
                            />
                            <Form.Check.Label>
                                <div>
                                    <div>{analyser.displayName}</div>
                                    <div>{analyser.description}</div>
                                </div>
                            </Form.Check.Label>
                        </Form.Check>
                    </li>
                ))}
            </ul>

            <AlgorithmOptions
                validityChanged={props.validityChanged}
                dataChanged={(data: AlgorithmOptionsData) => props.dataChanged({ ...props.value, ...data })}
                analyserConfig={props.analyserConfig}
                selectedAnalyser={selectedAlgorithm}
                previewVidUrl={props.previewVidUrl}
                previewImgUrl={props.previewImgUrl}
                value={props.value}
            />
        </div>
    );
}

export default AlgorithmSelectionPage;
