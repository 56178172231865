import { SetStateAction, useState } from "react";

export default function useNumberForInput(
    initial?: number,
): [number, (action: SetStateAction<number | undefined>) => void, (e: React.ChangeEvent<HTMLInputElement>) => void] {
    const [num, setNum] = useState<number | undefined>(initial);

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        try {
            const val = Math.floor(Number(e.target.value));
            setNum(val);
        } catch {
            setNum(undefined);
        }
    }

    return [num, setNum, onChange];
}
