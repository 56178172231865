import { MediaSource } from "../../../../models/media/MediaSource";
import { MutableRefObject, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faClapperboard, faList, faTag } from "@fortawesome/free-solid-svg-icons";
import { faChartNetwork, faClock, faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import useTagEventsCount from "../useTagEventsCount";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import useRunsCount from "../useRunsCount";
import ReviewVideoListItemDetails from "./ReviewVideoListItemDetails";
import ReviewVideoListItemRuns from "./ReviewVideoListItemRuns";
import ReviewVideoListItemTags from "./ReviewVideoListItemTags";
import useForceUpdate from "../../../../hooks/useForceUpdate";
import { useGetAnalysersConfigQuery } from "../../../../store/api/kinesense";
import ReviewVideoListItemClips from "./ReviewVideoListItemClips";
import useClipsCount from "../useClipsCount";

export interface ReviewVideoListItemFooterProps {
    data: MediaSource;
    viewId: string;
}

export type ReviewVideoListItemTrayContentProps = ReviewVideoListItemFooterProps & {
    contentRef: MutableRefObject<HTMLDivElement>;
};

function baseHandleButton(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
}

const TrayItems = ["Details", "Runs", "Clips", "Tags"] as const;
type TrayItem = (typeof TrayItems)[number];

const PLACEHOLDER_CHAR = "-" as const;

function ReviewVideoListItemFooter(props: ReviewVideoListItemFooterProps) {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);

    const [activeTrayItem, setActiveTrayItem] = useState<TrayItem>("Details");
    const [isTrayVisible, setIsTrayVisible] = useState(false);
    const forceUpdate = useForceUpdate();

    const tagEventsCount = useTagEventsCount(projectId, props.data.mediaId);
    const clipsCount = useClipsCount(projectId, props.data.mediaId);
    const runsCount = useRunsCount(props.data.mediaId);

    const trayContentRef = useRef<HTMLDivElement>(undefined);
    // Used to dynamically set the height so that the transition can be animated
    const trayHeight = trayContentRef.current?.clientHeight ?? 0;

    // Pre-fetch analysers config to avoid another loading step for the algorithms tab
    useGetAnalysersConfigQuery();

    function toggleIsTrayVisible() {
        setIsTrayVisible((prev) => !prev);
    }
    function toggleTrayItem(trayItem: TrayItem) {
        if (trayItem === activeTrayItem) {
            toggleIsTrayVisible();
        } else {
            setActiveTrayItem(trayItem);
            setIsTrayVisible(true);
        }
    }

    function renderTrayContent() {
        switch (activeTrayItem) {
            case "Details":
                return (
                    <ReviewVideoListItemDetails contentRef={trayContentRef} data={props.data} viewId={props.viewId} />
                );
            case "Runs":
                return (
                    <ReviewVideoListItemRuns
                        contentRef={trayContentRef}
                        data={props.data}
                        viewId={props.viewId}
                        forceUpdate={forceUpdate}
                    />
                );
            case "Clips":
                return <ReviewVideoListItemClips contentRef={trayContentRef} data={props.data} viewId={props.viewId} />;
            case "Tags":
                return <ReviewVideoListItemTags contentRef={trayContentRef} data={props.data} viewId={props.viewId} />;
        }
    }

    return (
        <section className="video-list-item-footer">
            <div className="gap-3 px-2 d-flex justify-content-between align-items-center">
                <button
                    className="button-with-icon"
                    onClick={(e) => {
                        baseHandleButton(e);
                        setIsTrayVisible(false);
                    }}
                >
                    <FontAwesomeIcon icon={faAngleUp} />
                </button>

                <button
                    title="Runs"
                    className="button-with-icon"
                    onClick={(e) => {
                        baseHandleButton(e);
                        toggleTrayItem("Details");
                    }}
                >
                    <FontAwesomeIcon icon={faList} />
                </button>

                <button
                    title="Runs"
                    className="button-with-icon"
                    onClick={(e) => {
                        baseHandleButton(e);
                        toggleTrayItem("Runs");
                    }}
                >
                    {runsCount ?? PLACEHOLDER_CHAR}
                    <FontAwesomeIcon icon={faChartNetwork} />
                </button>

                <button
                    className="button-with-icon"
                    onClick={(e) => {
                        baseHandleButton(e);
                        toggleTrayItem("Clips");
                    }}
                >
                    {clipsCount ?? PLACEHOLDER_CHAR}
                    <FontAwesomeIcon icon={faClapperboard} />
                </button>

                <button
                    className="button-with-icon"
                    onClick={(e) => {
                        baseHandleButton(e);
                        toggleTrayItem("Tags");
                    }}
                >
                    {tagEventsCount ?? PLACEHOLDER_CHAR}
                    <FontAwesomeIcon icon={faTag} />
                </button>

                <div className="d-none">
                    <button
                        className="button-with-icon"
                        onClick={(e) => {
                            baseHandleButton(e);
                        }}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                    <button
                        className="button-with-icon"
                        onClick={(e) => {
                            baseHandleButton(e);
                        }}
                    >
                        <FontAwesomeIcon icon={faClock} />
                    </button>
                </div>
            </div>

            <div
                className={`video-list-item-tray ${isTrayVisible ? "show" : ""}`}
                style={{ "--video-list-item-tray-height": `${trayHeight}px` } as React.CSSProperties}
            >
                {renderTrayContent()}
            </div>
        </section>
    );
}

export default ReviewVideoListItemFooter;
