import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { TaskItem } from "./TaskItem";
import { Task, Tasks } from "./Tasks";
import "./Tasks.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";

export const TaskArea = () => {
    const [currentTasks, setCurrentTasks] = useState<Task[]>([]);

    const removeTask = (task: Task) => {
        Tasks.removeCompletedTask(task);
    };

    const setTasks = (tasks: Task[]) => {
        setCurrentTasks([...tasks]);
    };

    useEffect(() => {
        Tasks.register(setTasks);
    }, []);

    return (
        <>
            <OverlayTrigger
                placement="top"
                trigger="click"
                rootClose
                overlay={
                    <Popover
                        id="popover-basic"
                        className="mb-1 me-2 task-area"
                        style={{ zIndex: "9999", minWidth: "25rem" }}
                    >
                        <Popover.Header as="h3">Tasks</Popover.Header>
                        <Popover.Body>
                            <div className="gap-2 p-0 toast-scroll d-flex flex-column">
                                {currentTasks.length > 0 ? (
                                    currentTasks
                                        .sort((a, b) => (a.isFinished ? (b.isFinished ? 0 : 1) : -1))
                                        .map((task) => <TaskItem task={task} onTaskClosed={removeTask} key={task.id} />)
                                ) : (
                                    <span key="0" className="text-muted">
                                        There are no active tasks
                                    </span>
                                )}
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <button className="task-area-toggle button-with-icon position-relative" title="Tasks">
                    <FontAwesomeIcon icon={faTasks} />

                    {Tasks.hasActiveTasks() && <span className="bg-success task-indicator rounded-circle" />}
                </button>
            </OverlayTrigger>
        </>
    );
};
